import { Component, OnInit } from '@angular/core';
import { APPS } from '@config';
import { Menu } from '@design-system/feature/app-wrapper';
import { UserService } from '@features/auth';
import { filterTruthy } from '@shared-lib/rxjs';
import { take } from 'rxjs';

@Component({
  selector: 'service-inspection-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isImportManager = false;

  menu: Menu = {
    title: 'Smart Processes',
    titleIcon: 'pd-oh-tippingOperations',
    navigation: [
      {
        title: 'Overview',
        icon: 'assignment',
        routerLink: '/overview',
      },
      {
        title: 'Content management',
        icon: 'pd-oh-leverNotNeutral',
        routerLink: '/content-management',
        children: [
          {
            title: 'Inspection Lists',
            routerLink: '/content-management/inspection-lists',
          },
          {
            title: 'Categories',
            routerLink: '/content-management/categories',
          },
        ],
      },
    ],
  };

  constructor(private _userService: UserService) {}

  ngOnInit(): void {
    this._userService.isAuthorized$
      .pipe(filterTruthy(), take(1))
      .subscribe(
        () =>
          (this.isImportManager = this._userService.hasRole(
            APPS.SMART_PROCESSES.ROLES.IMPORT_MANAGER,
          )),
      );
  }
}
