import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { dsAnimations } from '@design-system/cdk/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ds-stepper-sidebar',
  templateUrl: './stepper-sidebar.component.html',
  styleUrls: ['./stepper-sidebar.component.scss'],
  animations: [dsAnimations.fadeIn, dsAnimations.fadeOut],
})
export class DsStepperSidebarComponent implements OnDestroy {
  @HostBinding('class.closed') get classClosed() {
    return !this.isExpanded;
  }
  @Input() isDisabled = false;
  @Input() customIcon: string;
  isExpanded = true;

  private _destroy$ = new Subject<void>();

  constructor(private _breakpointObserver: BreakpointObserver) {
    this._breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(takeUntil(this._destroy$))
      .subscribe((bpState) => {
        this.isExpanded = !bpState.matches;
      });
  }

  setExpanded(newState: boolean) {
    this.isExpanded = newState;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
