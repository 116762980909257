<div fxHide fxShow.lt-md>
  <div
    [@dsFadeIn]
    [@dsFadeOut]
    *ngIf="isExpanded"
    class="backdrop cdk-overlay-dark-backdrop print-hidden"
    (click)="setExpanded(!isExpanded)"
  ></div>
</div>
<mat-card class="full-width" [class.disable-overlay]="isDisabled">
  <ng-content></ng-content>
</mat-card>
<button
  mat-mini-fab
  color="accent"
  (click)="setExpanded(!isExpanded)"
  [class.collapsed]="!isExpanded"
  [class.expanded]="isExpanded"
>
  <mat-icon [fxHide]="!isExpanded"> chevron_right </mat-icon>
  <span
    class="info-icon"
    [fxHide]="isExpanded"
    *ngIf="!customIcon; else customIconTemplate"
    >i</span
  >
  <ng-template #customIconTemplate>
    <mat-icon
      *ngIf="!(customIcon | isPdIcon)"
      class="info-icon"
      [fxHide]="isExpanded"
      >{{ customIcon }}</mat-icon
    >
    <mat-icon
      *ngIf="customIcon | isPdIcon"
      class="info-icon"
      [fxHide]="isExpanded"
      fontSet="pd"
      [fontIcon]="customIcon"
    ></mat-icon>
  </ng-template>
</button>
