import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsDeprecatedStepperSidebarModule } from '@design-system/components/stepper-sidebar';
import { DsTableModule } from '@design-system/components/table';
import { TableSettingsIntl } from '@design-system/components/table-settings';
import { DsPageModule } from '@design-system/feature/page';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { DsSpotlightModule } from '@design-system/feature/spotlight';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { QRCodeModule } from 'angularx-qrcode';
import { CheckpointStatusIconsComponent } from './components/checkpoint-status-icons/checkpoint-status-icons.component';
import { ContinueFromOtherDeviceComponent } from './components/continue-from-other-device/continue-from-other-device.component';
import { HtmlContentWithActionsComponent } from './components/html-content-with-actions/html-content-with-actions.component';
import { RotateDeviceDialogComponent } from './components/rotate-device-dialog/rotate-device-dialog.component';
import { SpotlightFooterButtonComponent } from './components/spotlight-footer-button/spotlight-footer-button.component';
import { ThreeStateCheckboxComponent } from './components/three-state-checkbox/three-state-checkbox.component';
import { TranslationTableComponent } from './components/translation-table/translation-table.component';
import { UpdateTranslationsComponent } from './components/update-translations/update-translations.component';
import { AutosaveStatusLabelDirective } from './directives/autosave-status-label.directive';
import { TableSettingsI18n } from './i18n/table-settings-i18n';
import { CheckCheckpointStatePipe } from './pipes/check-checkpoint-state/check-checkpoint-state.pipe';
import { CheckpointDescriptionPipe } from './pipes/checkpoint-description/checkpoint-description.pipe';
import { CheckpointNamePipe } from './pipes/checkpoint-name/checkpoint-name.pipe';
import { CompanyWithAddressPipe } from './pipes/company-with-address/company-with-address.pipe';
import { CustomerWithAddressPipe } from './pipes/customer-with-address/customer-with-address.pipe';
import { ExtractCompanyCityPipe } from './pipes/extract-company-city/extract-company-city.pipe';
import { ExtractCompanyStreetPipe } from './pipes/extract-company-street/extract-company-street.pipe';
import { GetInspectionSummaryStatusPipe } from './pipes/get-inspection-summary-status/get-inspection-summary-status.pipe';
import { InspectionStateColorPipe } from './pipes/inspection-state-color/inspection-state-color.pipe';
import { InspectionStepAtPipe } from './pipes/inspection-step-at/inspection-step-at.pipe';
import { InspectionSummaryStatusColorPipe } from './pipes/inspection-summary-status-color/inspection-summary-status-color.pipe';
import { InspectionTypeNamePipe } from './pipes/inspection-type-name/inspection-type-name.pipe';
import { LanguagePipe } from './pipes/language/language.pipe';
import { NewLineToHtmlPipe } from './pipes/new-line-to-html/new-line-to-html.pipe';
import { RenderNotAvailableIfEmptyPipe } from './pipes/render-not-available-if-empty/render-not-available-if-empty.pipe';
import { ReplacePipe } from './pipes/replace-pipe/replace.pipe';
import { RowSpanForCheckpointPipe } from './pipes/row-span-for-checkpoint/row-span-for-checkpoint.pipe';
import { SnakeCasePipe } from './pipes/snake-case/snake-case.pipe';
import { ValidationMessagesTranslatePipe } from './pipes/validation-messages-translate/validation-messages-translate.pipe';

export function getTableSettingsIntl(translateService: TranslateService) {
  return new TableSettingsI18n(translateService).getIntl();
}

const sharedModules = [
  CommonModule,
  DsPageModule,
  DsSpacingModule,
  FlexLayoutModule,
  DsDeprecatedStepperSidebarModule,
  TranslateModule,
  DsTableModule,
  MatTableModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatRadioModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatSelectModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatTooltipModule,
  MatAutocompleteModule,
  FormsModule,
  ReactiveFormsModule,
  DsSnackbarModule,
  DsSpotlightModule,
  HttpClientModule,
  PdLetDirectiveModule,
  DsLoadingModule,
  DsPlaceholderModule,
  GoogleAnalytics4DirectiveModule,
  DsEquipmentIconModule,
];

@NgModule({
  imports: [...sharedModules, QRCodeModule],
  exports: [
    sharedModules,
    ReplacePipe,
    CheckpointStatusIconsComponent,
    SpotlightFooterButtonComponent,
    RotateDeviceDialogComponent,
    CompanyWithAddressPipe,
    ThreeStateCheckboxComponent,
    CustomerWithAddressPipe,
    ValidationMessagesTranslatePipe,
    ExtractCompanyStreetPipe,
    ExtractCompanyCityPipe,
    ContinueFromOtherDeviceComponent,
    RowSpanForCheckpointPipe,
    InspectionTypeNamePipe,
    AutosaveStatusLabelDirective,
    TranslationTableComponent,
    LanguagePipe,
    CheckpointNamePipe,
    CheckpointDescriptionPipe,
    SnakeCasePipe,
    InspectionStepAtPipe,
    InspectionSummaryStatusColorPipe,
    GetInspectionSummaryStatusPipe,
    InspectionStateColorPipe,
    NewLineToHtmlPipe,
    RenderNotAvailableIfEmptyPipe,
    CheckCheckpointStatePipe,
  ],
  providers: [
    {
      provide: TableSettingsIntl,
      deps: [TranslateService],
      useFactory: getTableSettingsIntl,
    },
  ],
  declarations: [
    ReplacePipe,
    CheckpointStatusIconsComponent,
    SpotlightFooterButtonComponent,
    RotateDeviceDialogComponent,
    CompanyWithAddressPipe,
    ThreeStateCheckboxComponent,
    CustomerWithAddressPipe,
    HtmlContentWithActionsComponent,
    ValidationMessagesTranslatePipe,
    ExtractCompanyStreetPipe,
    ExtractCompanyCityPipe,
    ContinueFromOtherDeviceComponent,
    RowSpanForCheckpointPipe,
    InspectionTypeNamePipe,
    AutosaveStatusLabelDirective,
    TranslationTableComponent,
    UpdateTranslationsComponent,
    LanguagePipe,
    CheckpointNamePipe,
    CheckpointDescriptionPipe,
    SnakeCasePipe,
    InspectionStepAtPipe,
    InspectionSummaryStatusColorPipe,
    GetInspectionSummaryStatusPipe,
    InspectionStateColorPipe,
    NewLineToHtmlPipe,
    RenderNotAvailableIfEmptyPipe,
    CheckCheckpointStatePipe,
  ],
})
export class SharedModule {}
